html {
    scroll-behavior: smooth;
}

.desktop {
    display: flex;
    background-color: #030f32f2;
    height: 100vh;
    color: #fff;
}

.desktop-left-container {
    padding: 7rem 0;
    padding-left: 6rem;
}

header {
    display: flex;
    flex-direction: column;
    gap: 4rem;
}

.desktop-logoImg {
    height: 5rem;
    width: 5rem;
}

.desktop-h1 {
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.desktop-h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.desktop-intro {
    font-size: 1.8rem;
}

.desktop-about-text {
    font-size: 1.6rem;
    color: #95a1b7;
    line-height: 1.5;
}


.desktop-about-text > span {
    color: #dfe5f0;
    font-weight: 600;
}

ul {
    list-style: none;
    padding: 0;
}

ul > a:link,
ul > a:visited,
ul > a:hover,
ul > a:active {
    text-decoration: none;
    font-size: 1.3rem;
    text-transform: uppercase;
    letter-spacing: .5rem;
    transition: all 0.3s;
    color: #dfe5f0;
}

ul > a:hover,
ul > a:active, 
.active:link,
.active:visited {
    color: #7fdcd4;
    font-weight: 600;
    font-size: 1.8rem;
}

.button:link,
.button:visited,
.button:hover,
.button:active {
    align-self: flex-start;
    padding: 1rem 2.2rem;
    background-color: #7fdcd4;
    color: #030f32;
    font-weight: 600;
    font-size: 1.6rem;
    border-radius: .75rem;
}

.button:hover {
    border: .1rem solid #7fdcd4;
    background-color: #030f32;
    color: #7fdcd4;
}

.desktop-right-side {
    margin: 7rem 0;
    padding-right: 6rem;
    padding-bottom: 7rem;
    overflow: scroll;
    scroll-behavior: smooth;
}

.desktop-socials {
    display: flex;
    gap: 2rem;
    color: #95a1b7;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.desktop > main {
    overflow: scroll;
    margin-bottom: 0;
}

.desktop-section-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.languagesContainer {
    display: flex;
    gap: 1rem;
}

.languagesContainer > span:hover {
    text-decoration: underline;
    color: #7fdcd4;
}

.resumeButton {
    display: flex;
    gap: 2rem;
    border: none;
    font-size: 1.6rem;
    align-self: flex-start;
    background-color: inherit;
    color: #dfe5f0;
    font-weight: 600;
    transition: all 0.3s;
}

footer {
    font-size: 1.6rem;
    color: #95a1b7;
    line-height: 1.5;
}

footer > a:link,
footer > a:hover,
footer > a:active {
    text-decoration: none;
    color: #dfe5f0;
}

footer > a:visited,
footer > a:active,
footer > a:hover {
    text-decoration: none;
    color: #7fdcd4;
}

.desktop-logo {
    font-size: 3rem;
    color: #7fdcd4;
}

@media (max-width: 1023px) {
    .desktop {
        display: none;
    }
}