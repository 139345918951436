/* Experience Component Styling */

.experienceContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.year, 
.summary {
    font-size: 1.6rem;
    color: #95a1b7;
    line-height: 1.75;
}

.jobTitleContainer {
    display: flex;
    flex-wrap: wrap;
    gap: .75rem;
    font-size: 1.6rem;
    color: #dfe5f0;
    font-weight: 600;
    transition: all 0.3s;
}

.link:link, 
.link:visited,
.link:active {
    text-decoration: none;
    color: #dfe5f0;
}

.jobTitleContainer:hover {
    color: #7fdcd4;
    font-size: 2rem;
}

/* Experience Component Styling */

/* Project Component Styling */

.projectCardContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.projectTitleLink:link,
.projectTitleLink:visited,
.projectTitleLink:hover,
.projectTitleLink:active {
    text-decoration: none;
    font-size: 2rem;
    letter-spacing: .2rem;
    color: #dfe5f0;
}

.projectTitleLink:visited,
.projectTitleLink:hover,
.projectTitleLink:active {
    color: #7fdcd4;
}

.projectSummary,
.projectSummary > a:link,
.projectSummary > a:visited,
.projectSummary > a:hover,
.projectSummary > a:active {
    font-size: 1.6rem;
    color: #95a1b7;
    line-height: 1.75;
    text-decoration: none;
}

.projectSummary > a:link,
.projectSummary > a:visited {
    color: #dfe5f0;
    font-weight: 600;
}

.projectSummary > a:hover,
.projectSummary > a:active {
    color: #7fdcd4;
}

.projectTechContainer {
    display: flex;
    flex-wrap: wrap;
    gap: .75rem;
    color: #7fdcd4;
}

.projectTechContainer > p {
    background-color: #7fdcd471;
    padding: 0.75rem 1rem;
    font-size: 1.2rem;
    border-radius: 1.5rem;
}

.projectCardContainer > img {
    width: 75%;
    height: auto;
    border: .15rem solid #7fdcd4;
    border-radius: .75rem;
}

@media (min-width: 1000px) {
    .projectTechContainer > p {
        font-size: 1.4rem;
        padding: .75rem 1.5rem;
        border-radius: 1.75rem;
    }
}

/* Project Component Styling */

/* Form Component Styling */

.form-input:focus, .form-textarea:focus {
    outline-style: none;
}

.error, 
.success {
    font-size: 1.4rem;
    grid-column: span 2;
    justify-self: center;
    margin-bottom: 0;
    color: #FF6666;
    font-weight: bold;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 0.7s;
    animation-duration: 0.7s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}
@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
} 

.success {
    color: #7fdcd4;
    margin-left: 6rem;
}

@media (min-width: 1000px) {
    .form-container-button {
        display: none;
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    .form-section {
        display: grid;
        grid-template-columns: .5fr 2fr;
    }

    .form-label {
        font-size: 1.5rem;
        color: #7fdcd4;
        font-weight: 600;
        letter-spacing: .1rem;
    }

    .form-input, .form-textarea {
        padding: .75rem;
        border-radius: .75rem;
        border: .15rem solid rgba(255, 0, 0, 0);
        transition: all 0.3s;
    }

    .form-textarea {
        height: 25rem;
    }

    .form-button {
        border: none;
        padding: .75rem 2rem;
        grid-column: span 2;
        justify-self: center;
        font-size: 1.4rem;
        font-weight: 600;
        background-color: #7fdcd4;
        border-radius: .75rem;
        color: #030f32;
        letter-spacing: .2rem;
        transition: all 0.3s;
    }

    .form-button:hover {
        background-color: #12b6a9;
    }
}

@media (max-width: 999px) {
    .success {
        margin-left: 0;
    }
    .form-container {
        display: flex;
        flex-direction: column;
        gap: .75rem;
        position: absolute;
        z-index: 100000;
        width: 100%;
        background-color: #030f32e1;
        padding: 1.5rem;
        border-radius: .75rem;
        backdrop-filter: blur(.3rem);
    }

    .form-container {
        -webkit-animation-name: slideInRight;
        animation-name: slideInRight;
        -webkit-animation-duration: 0.7s;
        animation-duration: 0.7s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }

    @-webkit-keyframes slideInRight {
        0% {
            -webkit-transform: translateX(100%);
            transform: translateX(100%);
            visibility: visible;
        }
        100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    }
    @keyframes slideInRight {
        0% {
            -webkit-transform: translateX(100%);
            transform: translateX(100%);
            visibility: visible;
        }
        100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    }

    .form-container-button {
        border: none;
        background-color: #030f3200;
        color: #95a1b7;
        font-weight: 600;
        font-size: 1.2rem;
        align-self: flex-end;
        margin-right: 1rem;
        cursor: pointer;
    }

    form,
    .form-section {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        border-radius: .75rem;
    }

    .form-section {
        gap: 0rem;
    }

    .form-label {
        font-size: 1.5rem;
        color: #7fdcd4;
        font-weight: 600;
        letter-spacing: .1rem;
    }

    .form-input, .form-textarea {
        padding: .75rem;
        border-radius: .75rem;
        border: none;
    }

    .form-textarea {
        height: 25rem;
    }

    .form-button {
        border: none;
        padding: .75rem 2rem;
        align-self: flex-start;
        font-size: 1.4rem;
        font-weight: 600;
        background-color: #7fdcd4;
        border-radius: .75rem;
        color: #030f32;
        letter-spacing: .2rem;
    }
}

/* Form Component Styling */