.mobile {
    display: flex;
    flex-direction: column;
    background-color: #030f32f2;
    padding: 3.5rem 2rem;
}

html {
    scroll-behavior: smooth;
}

main {
    display: flex;
    flex-direction: column;
    gap: 5rem;
    margin-bottom: 5rem;
}

header {
    margin-bottom: 12.5rem;
}

nav {
    position: relative;
}

h1 {
    font-size: 4rem;
    color: #dfe5f0;
    font-weight: 600;
}

h2 {
    font-size: 1.8rem;
    color: #dfe5f0;
    font-weight: 400;
}

.intro {
    font-size: 1.6rem;
    color: #95a1b7;
}

h1, h2, .intro {
    margin-bottom: 2.5rem;
}

.socials {
    display: flex;
    gap: 2rem;
    color: #95a1b7;
    list-style: none;
    padding-left: 0;
}

.logo {
    font-size: 3rem;
    color: #7fdcd4;
}

.logoImg {
    height: 5rem;
    width: 5rem;
} 

.section-title {
    font-size: 1.6rem;
    color: #7fdcd4;
    margin-bottom: 5rem;
    font-weight: 600;
    padding: 2rem 0;
    text-transform: uppercase;
    letter-spacing: .2rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #111b39cb;
    padding: 2rem 0;
    backdrop-filter: blur(.3rem);
}

.menu-button {
    border: .1rem solid #030f32;
    background-color: #7fdcd4;
    padding: 0 1.5rem;
    border-radius: .75rem;
    font-size: 1.6rem;
    font-weight: 600;
    letter-spacing: .1rem;
    text-transform: uppercase;
    color: #030f32;
    cursor: pointer;
}

.section-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.about-text {
    font-size: 1.6rem;
    color: #95a1b7;
    line-height: 1.5;
}


.about-text > span {
    color: #dfe5f0;
    font-weight: 600;
}

.csoon {
    font-size: 1.6rem;
    font-weight: 600;
    color: #7fdcd4;
}

footer {
    font-size: 1.6rem;
    color: #95a1b7;
    line-height: 1.5;
}

footer > a:link,
footer > a:hover,
footer > a:active {
    text-decoration: none;
    color: #dfe5f0;
}

footer > a:visited,
footer > a:active,
footer > a:hover {
    text-decoration: none;
    color: #7fdcd4;
}

@media (min-width: 1024px) {
    .mobile {
        display: none;
    }
}